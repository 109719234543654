import { Todo } from "../model/Todo";

const todos: Array<Todo> = [
    { id: 'a', title: 'Test A', content: 'This is content', targetTimestamp: -1, completed: false, archived: false },
    { id: 'b', title: 'Test B', content: 'This is content', targetTimestamp: -1, completed: false, archived: false },
    { id: 'c', title: 'Test C', content: 'This is content', targetTimestamp: -1, completed: false, archived: false }
];

const fakeTodosNum = 1000;
for(let f = 0; f < fakeTodosNum; f++) {
    todos.push({
        id: f.toString(),
        title: 'Test ' + f,
        content: 'content ' + f,
        targetTimestamp: -1,
        completed: false,
        archived: false,
    });
}


export const getTodos = (): Array<Todo> => todos;

export const getTodo = (id: string): Todo | undefined => todos.find(m => m.id === id);

export const getTodosMinMax = (min: number, max: number): Array<Todo> => {
    const items: Array<Todo> = [];
    for(let i = min; i < max; i++) {
        items.push(todos[i]);
    }
    return items;
}