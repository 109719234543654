import { useState } from 'react';
// import { Message, getMessage } from '../data/messages';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
// @ts-ignore
// import { personCircle } from 'ionicons/icons';
import { useParams } from 'react-router';

import { Todo } from '../model/Todo';
import { getTodo } from '../data/todos';

import TodoItem from '../components/TodoItem';

import './TodoView.css';

function TodoView() {
  const [todo, setTodo] = useState<Todo>();
  const params = useParams<{ id: string }>();

  useIonViewWillEnter(() => {
    console.log('viewWillEnter...');
    const msg = getTodo(params.id);
    console.log(msg);
    setTodo(msg);
  });

  return (
    <IonPage id="view-message-page">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="List" defaultHref="/list"></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {todo ? (
          <TodoItem todo={todo} />
        ) : (
          <div>Todo not found</div>
        )}
      </IonContent>
    </IonPage>
  );
}

export default TodoView;
