import { useState } from 'react';
import { Todo } from '../model/Todo';
import { getTodosMinMax } from '../data/todos';
import TodoItem from '../components/TodoItem';
// import { Message, getMessages } from '../data/messages';
import {
    IonContent,
    IonHeader,
    IonList,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    useIonViewWillEnter
} from '@ionic/react';
import './Home.css';
import { IonInfiniteScrollCustomEvent } from '@ionic/core/components';

const Home: React.FC = () => {

    const [todos, setTodos] = useState<Todo[]>([]);

    const [isInfiniteDisabled, setIsInfiniteDisabled] = useState<boolean>(false);

    const pushData = () => {
        const max = todos.length + 20;
        const min = max - 20;
        const newData = getTodosMinMax(min, max);
        setTodos(todos.concat(newData));
    };

    const loadData = async (ev: IonInfiniteScrollCustomEvent<void>): Promise<void> => {
        pushData();


        ev.target.complete();
        if (todos.length === 1000) {
            setIsInfiniteDisabled(true);
        }
    };

    useIonViewWillEnter(() => {
        // setTodos(getTodos());
        pushData();
    });

    const refresh = (e: CustomEvent) => {
        setTimeout(() => {
            e.detail.complete();
        }, 3000);
    };

    return (
        <IonPage id="list-page">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Todos</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">
                            Todos...
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonList>
                    {todos.map(t => <TodoItem key={t.id} todo={t} />)}
                </IonList>

                <IonInfiniteScroll
                    onIonInfinite={loadData}
                    threshold="100px"
                    disabled={isInfiniteDisabled}
                >
                    <IonInfiniteScrollContent
                        loadingSpinner="bubbles"
                        loadingText="Loading more data..."
                    />
                </IonInfiniteScroll>
            </IonContent>
        </IonPage>
    );
};

export default Home;
