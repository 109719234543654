import {
    IonItem,
    IonLabel,
    IonNote
} from '@ionic/react';

import { Todo } from '../model/Todo';
import './TodoItem.css';

interface TodoItemProps {
    todo: Todo;
}

const TodoItem: React.FC<TodoItemProps> = ({ todo }) => {
    return (
        <IonItem routerLink={`/todo/${todo.id}`} detail={false}>
            <div slot="start" className="dot dot-unread"></div>
            <IonLabel className="ion-text-wrap">
                <h2>
                    {todo.title}
                    <span className="date">
                        <IonNote>{todo.targetTimestamp}</IonNote>
                    </span>
                </h2>
                <p>{todo.content}</p>
            </IonLabel>
        </IonItem>
    );
};

export default TodoItem;
